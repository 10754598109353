
.movie_page {
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  .images {
    margin-right: 60px;
  }
  .info {
    .title {
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      h1 {
        font-size: 30px;
      }
    }
    .desc {
      font-size: 25px;
      margin-bottom: 15px;

    }
    .overview {
      font-size: 22px;
      margin-bottom: 15px;

    }
    .review {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        margin-right: 10px;
      }
      margin-bottom: 15px;
    }
    .date {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;
    }
  .genres_field {
    display: flex;
    flex-direction: row;
    .genres {
      margin-left: 20px;
        >span {
          margin-right: 10px;
      }
    }
  }
    .btn {
      text-align: center;
      margin-top: 30px;
      a {
        width: 250px;
        text-align: center;
        padding: 15px 45px;
        border: 1px solid;
        background-color: var(--color-primary);
        color: var(--color-secondary);
        cursor: pointer;
        transition: 0.4s;
        font-size: 20px;
        &:hover {
          background-color: var(--color-secondary);
          color: var(--color-primary);
        }
      }
    }
  }
}