.movies_block {
  h1 {
    color: #222627;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 10px;
    text-align: center;
    margin-bottom: 20px;
  }
  .movies {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: 20px;
    .movie_nth {
      background-color: var(--color-primary);
      color: var(--color-secondary);
      cursor: pointer;
      transition: 0.4s;
      overflow: hidden;
      .image_field {
        position: relative;
        .icon-heart {
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 25px;

          &.active {
            color: red;
          }
        }
        .image {
          img {
            object-fit: contain;
            width: 100%;
            transition: 0.4s;
            &:hover {
              transform: scale(1.1);
            }
          }
          
        }
      }

      .movie_info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 20px 30px;
        .title {
          font-size: 20px;
          font-weight: bold;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
        }
        .review {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            margin-right: 10px;
          }
        }
        .date {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
        .description {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
        }
        .favorites {
          padding: 5px 10px;
          text-align: center;
          background-color: #7a20a2;
          color: var(--color-secondary);
          border-radius: 50px;
          width: 75px;
        }
      }
      &:hover {
        box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.288);
      }
    }
  }
  .page_info {
    float: right;
    margin-top: 10px;
  }
  .btn {
    text-align: center;
    margin-top: 20px;
    >button {
      width: 150px;
      padding: 15px;
      text-align: center;
      border: 1px solid;
      background-color: var(--color-primary);
      color: var(--color-secondary);
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        background-color: var(--color-secondary);
        color: var(--color-primary);
      }
    }
  }
}